<template>
  <b-card class="login-body mb-5">
    <h3 v-if="!error" class="text-center mt-4 mb-4">{{$t('ACCOUNT.LOGGING_IN')}}</h3>
    <div v-else>
      <h3 style="text-align: center;">{{$t('ACCOUNT.NO_ACCESS_HEADER')}}</h3>
      <div class="mt-8 alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text"><p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p><p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p></div>
      </div>
    </div>

    <b-modal ref="accept-terms" :title="$t('PAGES.TERMS.ACCEPT')" hide-header-close no-close-on-backdrop>
      <template v-for="(term) in accept_terms">
        <TermsCheckbox :term="term" @term_checked="term_checked" @show_term="show_term"/>
      </template>

      <template #modal-footer>
        <b-button variant="primary" @click="confirm_terms()" :disabled="!mandatory_accepted">{{$t('COMMON.CONTINUE')}}</b-button>
      </template>

    </b-modal>

    <b-modal ref="show-term" size="lg" :title="selected_term.name" ok-only :ok-title="$t('COMMON.CLOSE')">
      <p class="my-4" :html="selected_term.template" v-html="selected_term.template"></p>
    </b-modal>

  </b-card>

</template>

<style lang="scss" scoped>
.underline-on-hover:hover {
  text-decoration: underline !important;
}
.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>

import dayjs from 'dayjs';
import axios from 'axios';
import { initUserMemlist } from '@/core/services/initUserMemlist.js';
import TermsCheckbox from '@/view/components/terms/TermsCheckbox.vue';
import { updateAcceptedTerms } from '@/core/services/initUserMemlist';
import {
  SET_NEED_TERMS
} from '@/core/services/store/auth.module';

export default {
  name: 'auth-forward',

  components: {
    TermsCheckbox
  },

  data() {
    return {
      error: null,
      accept_terms: [],
      selected_term: {}
    };
  },

  async mounted() {

    initUserMemlist((successAuthenticate, successInit) => {

      if (successAuthenticate) {

        const terms = this.$store.getters['terms'];
        const active_user_terms = terms.filter(item => item.is_active && item.audience === 'USER');
        const accepted_terms = this.$store.getters['acceptedTerms'];
       
        this.accept_terms = active_user_terms.filter(user_term => !accepted_terms.find(accepted_term => accepted_term.company_term.id === user_term.id));

        if (this.accept_terms && this.accept_terms.length > 0) {
          
          this.$store.dispatch(SET_NEED_TERMS, true);

          this.$refs['accept-terms'].show();
        }
        else {
          this.continue_forward();
        }
      }
    });
  },

  computed: {
    mandatory_accepted() {
      return !this.accept_terms.find(item => !item.accepted);
    }
  },

  methods: {

    term_checked(term, value) {
      
      this.accept_terms = this.accept_terms.map(item => {
        if (item.id === term.id) {
          item.accepted = value;
        }
        return item;
      });
      
    },

    show_term(term) {
      this.selected_term = term;
      this.$refs['show-term'].show();
    },

    confirm_terms() {
      this.$refs['accept-terms'].hide();
      this.accept_terms.forEach(async (term) => {
        if (term.accepted) {
          const res = await axios.post(`/company_term_user`, { company_term_id: term.id });
        }
      });

      this.$store.dispatch(SET_NEED_TERMS, false);

      updateAcceptedTerms(); // this is needed in case accepted terms changed so the store has correct data

      this.continue_forward();
    },

    continue_forward() {

      const currentAccess = this.$store.getters['currentAccess'];

      let ok_page = 'ml-dashboard';
      let found = false;
    
      if (!(ok_page in currentAccess)) {

        for (const k in currentAccess) {
          ok_page = k;
          found = true;
          break;
        }

      }
      else {
        found = true;
      }
      
      console.log('ok_page', ok_page);
      console.log('found', found);

      if (!found) {
        this.error = 'no_access';
      }
      else {
        localStorage.setItem('forwarding', undefined);
        this.$router.push({ name: ok_page });
      }
    },
  }
};
</script>

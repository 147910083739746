<template>
  <div class="tacbox">
    <input
      id="checkbox"
      type="checkbox"
      v-model="local_term.accepted"
      @change="$emit('term_checked', local_term, $event.target.checked)"
    />
    <span>{{ pretext }}<a class="underline-on-hover" href="#" @click="$emit('show_term', local_term)">{{ linktext }}</a>{{ posttext }} {{term.is_mandatory ? '('+$t('COMMON.MANDATORY')+')' : ''}}</span>
  </div>
</template>

<style scoped>
.tacbox {
  display: inline-block;
  padding: 1em;
  border: 1px solid #ddd;
  background-color: #eee;
  width: 100%;
}

input {
  height: 2em;
  width: 2em;
  vertical-align: middle;
  float: left;
}

.tacbox span {
  display: block;
  overflow: auto;
  margin-left: 8px;
  padding-left: 8px;
  padding-top: 2px;
}

.underline-on-hover:hover {
  text-decoration: underline !important;
}

</style>

<script>

export default {
  name: 'TermsCheckbox',
  props: ['term'],
  emits: ['term_checked', 'show_term'],
  components: {},
  data() {
    return {
      local_term: {},
      pretext: '',
      posttext: '',
      linktext: ''
    };
  },
  watch: {
    term: {
      deep: true,
      handler(val) {
        this.local_term = { ...val };
      }
    }
  },
  mounted() {
    if (this.term) {
      this.local_term = { ...this.term };
    }
    var parts = this.term.descr.split('%');

    if (parts.length < 2) {
      this.linktext = this.term.descr;
      return;
    }

    if (parts.length === 2) {
      this.pretext = parts[0];
      this.linktext = parts[1];
      return;
    }

    this.pretext = parts[0];
    this.linktext = parts[1];
    this.posttext = parts[2];
  },

  methods: {}
};
</script>
